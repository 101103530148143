import { useAppDispatch, useAppSelector } from "common/hooks";
import { useAppParams } from "common/hooks/useAppParams";
import { updateFavoritesListingsIds } from "redux/slices";
import { useFavoriteRemove } from "api/user/hooks";
import { getFavoritesIdsFromLocalStorage } from "utils/hooks/useAddRemoveFavorites";
import { useGetBasicListingsOfAuthUser } from "api/user/hooks/get-basic-listings-of-auth-user";
import { useGetFavoritesBasicListingsByIds } from "../listings/hooks/favorites-basic-listings-by-ids";
import { useGetBasicListingsInCounty } from "../listings/hooks/basic-listings-in-county";

export const useGetGenericBasicListings = () => {
  const dispatch = useAppDispatch();
  const { countyId, isCountryViewMode } = useAppParams();
  const { mutate: removeUserFavorite } = useFavoriteRemove();

  const appSlice = useAppSelector(({ appSlice }) => appSlice);
  const listingsSlice = useAppSelector(({ listingsSlice }) => listingsSlice);
  const { activeMenuView } = appSlice;
  const isFavoritesView = activeMenuView === "favorites";
  const isOwnListingsView = activeMenuView === "own-listings";
  const { favoritesListingsIds } = listingsSlice;

  const updateListOfFavoritesIdsIfSomeAreNotFound = (updatedList: string[]) => {
    const localStorageFavorites = getFavoritesIdsFromLocalStorage();

    // If there are some ids in localStorage, it means the user is not authenticated and we need to update the localStorage
    if (localStorageFavorites.length > 0) {
      const newLocalStorageFavorites = localStorageFavorites.filter((id) => updatedList.includes(id));
      localStorage.setItem("favoritesListings", newLocalStorageFavorites.join(","));

      dispatch(updateFavoritesListingsIds(updatedList));
    }

    // Otherwise, we update the db
    for (let i = 0; i < favoritesListingsIds.length; i += 1) {
      const favoriteId = favoritesListingsIds[i];
      if (!updatedList.includes(favoriteId)) {
        removeUserFavorite({ listingId: favoriteId });
      }
    }

    dispatch(updateFavoritesListingsIds(updatedList));
  };

  // Listings in county
  const { data: basicListingsInCounty, isLoading: isBasicListingsInCountyLoading } = useGetBasicListingsInCounty({
    countyId,
    enabled: !isCountryViewMode && !isFavoritesView && !isOwnListingsView,
  });

  // Favorites listings
  const { data: favoritesBasicListings, isLoading: isFavoritesBasicListingsLoading } =
    useGetFavoritesBasicListingsByIds({
      listingIds: favoritesListingsIds,
      enabled: isFavoritesView,
      onMissingIds: updateListOfFavoritesIdsIfSomeAreNotFound,
    });

  // User listings
  const { data: userBasicListings, isLoading: isUserBasicListingsLoading } = useGetBasicListingsOfAuthUser({
    enabled: isOwnListingsView,
  });

  return {
    listings: basicListingsInCounty || favoritesBasicListings || userBasicListings,
    isLoading: isBasicListingsInCountyLoading || isFavoritesBasicListingsLoading || isUserBasicListingsLoading,
  };
};
