import { Skeleton, styled } from "@mui/material";
import { ComponentProps } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InfoIcon from "@mui/icons-material/Info";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import { theme } from "theme";
import { useAppSelector, useWindowDimensions } from "common/hooks";
import { useAppParams } from "common/hooks/useAppParams";
import { NEUTRAL_RED } from "utils/constants";
import { useGetGenericBasicListings } from "api/use-generic-basic-listings";

type ListingsCountProps = ComponentProps<"div"> & {
  fromMap?: boolean;
};

export const ListingsCount = (props: ListingsCountProps) => {
  const { fromMap } = props;
  const { isListingDetailsMode, isCountryViewMode } = useAppParams();
  const { activeMenuView, sidebarOpen } = useAppSelector(({ appSlice }) => appSlice);
  const { isMobile } = useWindowDimensions();

  const isFavoritesView = activeMenuView === "favorites";
  const isOwnListingsView = activeMenuView === "own-listings";
  const isFavoritesOrOwnListingsView = isFavoritesView || isOwnListingsView;

  const { listings, isLoading } = useGetGenericBasicListings();

  if ((isCountryViewMode && !isFavoritesOrOwnListingsView) || (isMobile && isListingDetailsMode)) {
    return (
      <StyledListingsCount className="map-count" {...props}>
        <InfoIcon />
        <span>Click pe județ sau mărește zoom-ul</span>
      </StyledListingsCount>
    );
  }

  if (fromMap && !isMobile && sidebarOpen) {
    return null;
  }

  const count = listings ? Object.values(listings).length : null;

  if (isLoading) {
    return <Skeleton style={{ display: "inline-block" }} height="18px" width="30px" />;
  }

  return isFavoritesView ? (
    <StyledListingsCount {...props}>
      <FavoriteIcon sx={{ color: NEUTRAL_RED }} />
      {count ? (
        <span>
          <b style={{ width: "auto" }}>{count}</b>
          {count === 1 ? " favorit" : " favorite"}
          {/* {` ${count === 1 ? "anunț favorit" : "anunțuri favorite"} în zona selectată`} */}
        </span>
      ) : (
        <span>0 anunțuri</span>
        // <span>Niciun anunț în zona selectată</span>
      )}
    </StyledListingsCount>
  ) : isOwnListingsView ? (
    <StyledListingsCount {...props}>
      <RoomPreferencesIcon sx={{ color: theme.palette.secondary.main }} />
      {count ? (
        <span>
          <b style={{ width: "auto" }}>{count}</b>
          {count === 1 ? "anunț" : "anunțuri"}
          {/* {` ${count === 1 ? "anunț propriu" : "anunțuri proprii"} în zona selectată`} */}
        </span>
      ) : (
        <span>0 anunțuri</span>
        // <span>Nu există anunțuri postate în zona selectată</span>
      )}
    </StyledListingsCount>
  ) : (
    <StyledListingsCount {...props}>
      <InfoIcon />
      {count ? (
        <span>
          <b style={{ width: "auto" }}>{count}</b> {count === 1 ? "anunț" : "anunțuri"}
          {/* <b style={{ width: "auto" }}>{count}</b> {`${count === 1 ? "anunț" : "anunțuri"} în zona selectată`} */}
        </span>
      ) : (
        <span>0 anunțuri</span>
        // <span>Niciun anunț în zona selectată</span>
      )}
    </StyledListingsCount>
  );
};

const StyledListingsCount = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid ${theme.palette.grey[300]};
  background-color: white;
  padding: 4px 10px;
  font-weight: 400;
  color: ${theme.palette.grey[800]};
  font-size: 14px;
  user-select: none;
  min-width: 50px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
  box-sizing: border-box;
  position: relative;

  > svg {
    position: relative;
    overflow: visible;
  }

  &.map-count {
    position: absolute;
    box-sizing: border-box;
    left: 145px;
    top: 12px;
    padding: 4px 12px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    width: auto;
    font-weight: 400;

    ${theme.breakpoints.down(375)} {
      top: 55px;
      right: 10px;
      left: 10px;
    }
  }

  ${theme.breakpoints.down(500)} {
    font-size: 11px;
  }
`;
