import axios from "axios";
import {
  IContactUsParams,
  IFacebookAuthParams,
  IGoogleAuthParams,
  ILoginParams,
  IPasswordResetParams,
  IRegisterParams,
  IRequestPasswordResetParams,
  IUserAddFavoritesParams,
  IUserDetailsParams,
  IUserLogoutParams,
  IUserRemoveFavoriteParams,
} from "./user-types";
import { DEFAULT_REQ_TIMEOUT } from "../utils";

const baseUrl =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_SERVER_URL_LOCAL : process.env.REACT_APP_SERVER_URL;

export class UserApi {
  public static async registerUser({ firstName, lastName, email, phone, password }: IRegisterParams) {
    return axios.post(
      `${baseUrl}/user/register`,
      {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phone,
        password,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async loginUser({ email, password }: ILoginParams) {
    return axios.post(
      `${baseUrl}/user/login`,
      {
        email,
        password,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async logoutUser({ token }: IUserLogoutParams) {
    return axios.post(
      `${baseUrl}/user/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async facebookAuth({ tokenId, name, email }: IFacebookAuthParams) {
    return axios.post(
      `${baseUrl}/user/facebook-auth`,
      {
        tokenId,
        name,
        email,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async googleAuth({ tokenId }: IGoogleAuthParams) {
    return axios.post(
      `${baseUrl}/user/google-auth`,
      {
        google_token: tokenId,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async getUserDetails({ token }: IUserDetailsParams) {
    return axios.get(`${baseUrl}/user/my-details/${token}`, {
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async getPublicUserProfile({ userId }: { userId: string }) {
    return axios.get(`${baseUrl?.replace(".io/api", ".io/v1")}/user-profile/${userId}`, {
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async getOwnDetails({ token }: IUserDetailsParams) {
    return axios.get(`${baseUrl?.replace(".io/api", ".io/v1")}/user`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async contactUs({ name, email, message }: IContactUsParams) {
    return axios.post(
      `${baseUrl}/user/contact-us`,
      {
        name,
        email,
        message,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async addUserFavorites({ listingIds, token }: IUserAddFavoritesParams) {
    return axios.post(
      `${baseUrl}/user/listing/favorites`,
      {
        favorite_ids: listingIds,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async removeUserFavorite({ listingId, token }: IUserRemoveFavoriteParams) {
    return axios.delete(`${baseUrl}/user/listing/favorite/${listingId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async requestPasswordReset({ email }: IRequestPasswordResetParams) {
    return axios.post(
      `${baseUrl}/user/request-password-reset`,
      {
        email,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async passwordReset({ token, userId, password }: IPasswordResetParams) {
    return axios.post(
      `${baseUrl}/user/password-reset`,
      {
        token,
        user_id: userId,
        new_password: password,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async validateEmail(validateEmailToken: string, userId: string) {
    return axios.post(
      `${baseUrl}/user/email-verification`,
      {
        token: validateEmailToken,
        user_id: userId,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async sendPhoneNumberVerificationCode(token: string, phoneNumber: string) {
    return axios.post(
      `${baseUrl}/user/phone-number-verification`,
      {
        phone_number: phoneNumber,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  public static async verifyPhoneNumberCode(token: string, phoneNumber: string, code: string) {
    return axios.post(
      `${baseUrl}/user/sms-code-verification`,
      {
        phone_number: phoneNumber,
        code,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  public static async askResendValidateEmail(userToken: string, userId: string) {
    return axios.post(
      `${baseUrl}/user/resend-email-verification`,
      {
        token: userToken,
        user_id: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async updateUserName(userToken: string, firstName: string, lastName: string, imageUrl: string | null) {
    return axios.put(
      `${baseUrl}/user`,
      {
        first_name: firstName,
        last_name: lastName,
        profile_picture_url: imageUrl,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async deleteAccount(userToken: string) {
    return axios.delete(`${baseUrl?.replace(".io/api", ".io/v1")}/user`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async deleteOwnListing(token: string, listingId: string) {
    return axios.delete(`${baseUrl}/user/listing/${listingId}`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async updateListingStatus(token: string, listingId: string, isActive: boolean) {
    return axios.put(
      `${baseUrl}/user/listing/${listingId}`,
      {
        is_active: isActive,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  public static async getAWSProfilePictureUploadUrl({ token }: { token: string }) {
    return axios.get<{
      data: string;
    }>(`${baseUrl?.replace(".io/api", ".io/v1")}/user/profile-picture-upload-url`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async getBasicListingsOfAuthUser(token: string) {
    return axios.get(`${baseUrl}/user/listings`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
