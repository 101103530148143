import { useQuery } from "react-query";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import {
  basicListingBEResponseSchema,
  useProcessBasicListings,
} from "api/listings/hooks/helpers/process-basic-listings";
import { useAppSelector } from "common/hooks";
import { QueriesKeys } from "common/types/general";
import { UserApi } from "api/user/user-api";
import { SHORT_CACHING, logZodError } from "../../../utils";
import { useHandleApiError } from "../../../useHandleErrors";

interface BasicListingsProps {
  enabled?: boolean;
}

export const useGetBasicListingsOfAuthUser = (props: BasicListingsProps) => {
  const { enabled = false } = props;
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const { user } = useAppSelector((state) => state.authSlice);
  const { token } = user || {};

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["basicListingsOfAuthUser" as QueriesKeys, token, enabled],
    async () => {
      addLoadingEl("basic-listings-of-auth-user");

      const res = await UserApi.getBasicListingsOfAuthUser(token!);

      removeLoadingEl("basic-listings-of-auth-user");

      const { data } = res.data; // .filter(({ id }: any) => id !== "d27369e4-61c9-46a1-b350-afac64ff74e9");

      const parsed = basicListingBEResponseSchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return data;
      }

      return parsed.data;
    },
    {
      ...SHORT_CACHING,
      enabled: enabled && !!token && !!user,
    }
  );

  // TODO: Move this inside useQuery hook, otherwise it will be called on every render
  const { processedListings } = useProcessBasicListings(data);

  // Handle errors
  useHandleApiError({
    isError,
    message: "Nu s-au putut încărca anunțurile proprii. Reîncarcă pagina și încearcă din nou.",
  });

  return { data: processedListings, isLoading, isSuccess, isError };
};
